import { Application } from "@nutrien-operations/config";

export const basePath = "/mine-maintenance-portal";

const permissions = {
  public: "i-am-a-guid",
  admin: "101c81f8-1bc5-4caf-bb2b-e4101db1c4e0",
  checklistAdmin: "41b01a28-792b-43ea-a41b-ef3424d42951",
  checklistUser: "7f788035-09d1-4cc6-a5fe-695562a53057",
  planner: "404a7888-0037-494f-9ee8-fd5ad3df85d9",
  reportViewer: "d12c7003-593f-44ad-bd3a-680379ee7b08",
  shiftDataEditor: "9ff5ef78-66dc-4d35-9209-6b84e1ee9f3f",
  userAdmin: "4393a8eb-51cb-444e-a818-e882d1fb9469"
};

const applicationConfig: Application = {
  applicationId: "852973a6-3443-409c-8226-1ff64f656b9f",
  name: "@nutrien-operations/mine-maintenance-portal",
  displayName: "Mine Maintenance Portal",
  icon: "calendar-check",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "Mine Maintenance Portal",
    path: basePath,
    icon: "calendar-check",
    children: [
      {
        label: "Maintenance History",
        path: "/maintenance-history",
        permission: permissions.public
      },
      {
        label: "Shift",
        path: "/shift/shift-form",
        permission: permissions.shiftDataEditor,
        defaultCollapsed: true,
        children: [
          {
            label: "Shift Form",
            path: "/shift/shift-form",
            permission: permissions.shiftDataEditor
          },
          {
            label: "Review Shift Data",
            path: "/shift/review-shift-data",
            permission: permissions.shiftDataEditor
          },
          {
            label: "View Forecast",
            path: "/shift/view-forecast",
            permission: permissions.shiftDataEditor
          }
        ]
      },
      {
        label: "Planning",
        path: "/planning",
        permission: permissions.planner,
        defaultCollapsed: true,
        children: [
          {
            label: "Manage Forecast",
            path: "/planning/manage-forecast",
            permission: permissions.planner
          }
        ]
      },
      {
        label: "Checklist",
        path: "/checklist/checklist-entry",
        permission: permissions.checklistUser,
        defaultCollapsed: true,
        children: [
          {
            label: "Checklist Entry",
            path: "/checklist/checklist-entry",
            permission: permissions.checklistUser
          },
          {
            label: "Equipment Viewer",
            path: "/checklist/equipment-viewer",
            permission: permissions.checklistUser
          },
          {
            label: "Equipment History",
            path: "/checklist/equipment-history",
            permission: permissions.checklistUser
          }
        ]
      },
      {
        label: "Checklist Admin",
        path: "/checklist-admin/checklist-editor",
        permission: permissions.checklistAdmin,
        defaultCollapsed: true,
        children: [
          {
            label: "Checklist Editor",
            path: "/checklist-admin/checklist-editor",
            permission: permissions.checklistAdmin
          },
          {
            label: "New/Active Checklists",
            path: "/checklist-admin/new-active-checklists",
            permission: permissions.checklistAdmin
          },
          {
            label: "Equipment Editor",
            path: "/checklist-admin/equipment-editor",
            permission: permissions.checklistAdmin
          },
          {
            label: "Employees",
            path: "/checklist-admin/employees",
            permission: permissions.checklistAdmin
          },
          {
            label: "Crews",
            path: "/checklist-admin/crews",
            permission: permissions.checklistAdmin
          },
          {
            label: "Employee Groups",
            path: "/checklist-admin/employee-groups",
            permission: permissions.checklistAdmin
          }
        ]
      },
      {
        label: "Admin",
        path: "/admin/components",
        permission: permissions.admin,
        defaultCollapsed: true,
        children: [
          {
            label: "Components",
            path: "/admin/components",
            permission: permissions.admin
          },
          {
            label: "Crews",
            path: "/admin/crews",
            permission: permissions.admin
          },
          {
            label: "Employee Groups",
            path: "/admin/employee-groups",
            permission: permissions.admin
          },
          {
            label: "Employees",
            path: "/admin/employees",
            permission: permissions.admin
          },
          {
            label: "Equipment",
            path: "/admin/equipment",
            permission: permissions.admin
          },
          {
            label: "Equipment Types",
            path: "/admin/equipment-types",
            permission: permissions.admin
          },
          {
            label: "Department Equipment",
            path: "/admin/department-equipment",
            permission: permissions.admin
          },
          {
            label: "Jobs",
            path: "/admin/jobs",
            permission: permissions.admin
          },
          {
            label: "Subcomponents",
            path: "/admin/subcomponents",
            permission: permissions.admin
          },
          {
            label: "Delays",
            path: "/admin/delays",
            permission: permissions.admin
          },
          {
            label: "Report Subscriptions",
            path: "/admin/report-subscriptions",
            permission: permissions.admin
          }
        ]
      },
      {
        label: "User Admin",
        path: "/user-admin/roles",
        permission: permissions.userAdmin,
        defaultCollapsed: true,
        children: [
          {
            label: "Departments",
            path: "/user-admin/departments",
            permission: permissions.userAdmin
          }
        ]
      },
      {
        label: "Daily Reports",
        path: "/daily-reports",
        permission: permissions.reportViewer
      }
    ]
  }
};

export default applicationConfig;
